<template>
  <div class="settingScopeApproval">
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem active> Scope Approvals </CBreadcrumbItem>
    </teleport>

    <CCard style="min-height: 75vh">
      <CCardBody>
        <ConfirmDialog ref="confirmDialog" />
        <CRow class="flex-column-reverse flex-lg-row">
          <CCol class="col-12 col-lg-6 col-xxl-8">
            <Form ref="form" :form="form" />
          </CCol>
          <CCol class="col-12 col-lg-6 col-xxl-4">
            <CRow :xs="{ cols: 1, gutter: 4 }">
              <CCol class="text-end">
                <CButton
                  color="primary"
                  size="sm"
                  :disabled="!form.id"
                  @click="handleNew"
                >
                  Add new workflow
                </CButton>
              </CCol>
              <CCol>
                <SmartTable
                  :key="key"
                  :items="workflows"
                  :items-per-page="parseInt(workflows.length)"
                  :columns="fields"
                  :sorter-value="sort"
                  :selected-items="selected"
                  :loading="loadingTable"
                  :active-page="activePage"
                  :per-page="perPage"
                  :clear-text="
                    search.length ? 'No search results found' : 'No Data yet'
                  "
                  :pages="pages"
                  @sorter-change="onSort"
                  @selected-items-change="(s) => (selected = s)"
                  @update-active-page="(value) => (activePage = value)"
                  @update-per-page="(value) => (perPage = value)"
                >
                  <template #name="{ item }">
                    <span
                      class="tableLink pointer"
                      @click="checkCanEdit(item.id)"
                      >{{ item.name }}</span
                    >
                  </template>
                  <template #clear_text>
                    <div
                      class="table__clear d-flex align-items-center justify-content-center w-100"
                    >
                      <div class="tableEmptyTitle">No Workflow created yet</div>
                      <div class="tableEmptyDescription">
                        But we are almost there!
                      </div>
                      <img
                        class="tableEmptyImg"
                        src="@/assets/workflow_table.png"
                        alt="Workflow"
                      />
                    </div>
                  </template>
                  <template #button="{ item }">
                    <div class="d-flex justify-content-around">
                      <CIcon
                        name="cil-pencil"
                        class="m-2 text-primary pointer"
                        @click="checkCanEdit(item.id)"
                      />
                      <CIcon
                        name="cil-trash"
                        class="m-2 text-error pointer"
                        @click="deleteId(item.id)"
                      />
                    </div>
                  </template>
                </SmartTable>
              </CCol>
            </CRow>
          </CCol>
        </CRow>

        <CRow class="mt-3">
          <CCol class="col-12 col-lg-6 col-xxl-8">
            <CCol class="p-0 mb-4">
              <CDivider />
            </CCol>
            <CCol>
              <CRow :xs="{ gutterY: 3 }" :xxl="{ gutterY: 0 }" class="w-100">
                <CCol class="col-12 col-xxl-auto">
                  <CButton
                    class="w-100"
                    color="primary"
                    variant="outline"
                    :disabled="disabled"
                    @click="handleCancel"
                    >Cancel</CButton
                  >
                </CCol>
                <CCol class="col-12 col-xxl-auto">
                  <CButton
                    class="w-100"
                    color="primary"
                    :disabled="loading"
                    @click="save"
                    >Save</CButton
                  >
                </CCol>
                <CCol class="col-12 col-xxl-auto ms-auto">
                  <CButton
                    v-if="form.id"
                    variant="ghost"
                    class="w-100 text-error"
                    @click="deleteId(form.id)"
                  >
                    <CIcon name="cilTrash" />
                    Delete workflow
                  </CButton>
                  <CButton v-else variant="ghost" class="w-100 text-disabled">
                    <CIcon name="cilTrash" />
                    Delete workflow
                  </CButton>
                </CCol>
              </CRow>
            </CCol>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
